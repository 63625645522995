// BlockInfoText.module.scss
.wrapper {
    font-family: 'Montserrat', sans-serif;
    padding: 2.5rem;
    background: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(10px);
    border-radius: 15px;
    box-shadow: 
      0 8px 32px rgba(0, 0, 0, 0.1),
      inset 0 0 12px rgba(255, 255, 255, 0.3);
    transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
    position: relative;
    overflow: hidden;
    
    &:hover {
      transform: translateY(-5px);
      box-shadow: 
        0 12px 40px rgba(0, 0, 0, 0.15),
        inset 0 0 15px rgba(255, 255, 255, 0.4);
    }
  
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      width: 50%;
      height: 100%;
      background: linear-gradient(
        90deg,
        transparent,
        rgba(255, 255, 255, 0.4),
        transparent
      );
      transition: 0.8s;
    }
  
    &:hover::before {
      left: 100%;
    }
  
    @media (max-width: 768px) {
      padding: 1.5rem;
      width: 100% !important;
    }
  }
  
  .text {
    position: relative;
    z-index: 1;
  }
  
  .title {
    font-size: 2.2rem;
    font-weight: 700;
    margin-bottom: 1.8rem;
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 1.5px;
  
    &Underline {
      height: 3px;
      width: 60%;
      background: currentColor;
      position: absolute;
      bottom: -8px;
      left: 0;
      border-radius: 2px;
      transition: width 0.4s ease;
    }
  
    &:hover &Underline {
      width: 100%;
    }
  }
  
  .description {
    font-size: 1.1rem;
    line-height: 1.8;
    color: #444;
    margin: 0;
    font-weight: 400;
    position: relative;
  
    &::first-letter {
      font-size: 1.8em;
      font-weight: 700;
      color: #2A5EE6;
      float: left;
      margin-right: 8px;
      line-height: 0.8;
    }
  }