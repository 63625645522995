// Footer.module.scss
.wrapper {
  background: linear-gradient(135deg, #0A1F5C 0%, #2A5EE6 100%);
  color: white;
  padding: 80px 0 40px;
  position: relative;
  overflow: hidden;
}

.content {
  max-width: 1200px;
  margin: 0 auto;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
  margin-bottom: 60px;
}

.logoSection {
  .logo {
      width: 200px;
      margin-bottom: 20px;
      filter: drop-shadow(0 2px 4px rgba(0,0,0,0.2));
  }
  
  .tagline {
      font-size: 1.1rem;
      color: rgba(255,255,255,0.9);
      max-width: 250px;
      line-height: 1.4;
  }
}

.navColumns {
  display: flex;
  justify-content: center;
}

.navGroup {
  display: flex;
  flex-direction: column;
  gap: 15px;
  
  .navTitle {
      font-size: 1.2rem;
      font-weight: 600;
      margin-bottom: 10px;
      color: #FFD700;
  }
}

.navLink {
  color: white;
  text-decoration: none;
  transition: all 0.3s ease;
  position: relative;
  width: fit-content;
  
  &::after {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 0;
      height: 1px;
      background: #FFD700;
      transition: width 0.3s ease;
  }
  
  &:hover {
      color: #FFD700;
      
      &::after {
          width: 100%;
      }
  }
}

.ctaSection {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.filmfreewayButton {
  width: 230px;
  height: 70px;
  transition: all 0.3s ease;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0,0,0,0.2);
  
  img {
      width: 100%;
      height: 100%;
      object-fit: contain;
  }
  
  &:hover {
      box-shadow: 0 6px 20px rgba(0,0,0,0.3);
  }
}

.divider {
  height: 1px;
  background: linear-gradient(90deg, transparent 0%, rgba(255,255,255,0.3) 50%, transparent 100%);
  margin: 40px 0;
}

.bottomSection {
  text-align: center;
  
  .copyright {
      font-size: 0.9rem;
      color: rgba(255,255,255,0.7);
  }
}

@media (max-width: 768px) {
  .grid {
      grid-template-columns: 1fr;
      text-align: center;
  }
  
  .logoSection {
      display: flex;
      flex-direction: column;
      align-items: center;
      
      .tagline {
          max-width: none;
      }
  }
  
  .navGroup {
      align-items: center;
      margin-bottom: 30px;
  }
  
  .ctaSection {
      justify-content: center;
  }
  
  .filmfreewayButton {
      width: 200px;
      height: 60px;
  }
}