.wrapper{
    display: flex;
    width: 1200px;
    margin: 0 auto;
    @media screen and (max-width: 1200px) {
        width: 100%;
        padding: 0 30px;
    }
    @media screen and (max-width: 768px) {
        width: 100%;
        padding: 0 15px;
    }
}