// Presentation.module.scss
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  background: rgba(0,0,0,0.05);
}

.iframe {
  width: 100%;
  max-width: 1200px;
  min-height: 500px;
  border: none;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0,0,0,0.1);
  
  // Для мобильных устройств
  @media (max-width: 768px) {
      min-height: 300px;
      border-radius: 0;
      box-shadow: none;
  }
  
  // Для очень маленьких экранов
  @media (max-width: 480px) {
      min-height: 250px;
  }
}