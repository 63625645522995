// Banner.module.scss
.banner {
    height: 100vh;
    background: linear-gradient(135deg, #0A1F5C 0%, #2A5EE6 100%);
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.content {
    text-align: center;
    padding: 20px;
}

.title {
    font-size: 5rem;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 4px;
    margin-bottom: 20px;
    text-shadow: 0 5px 15px rgba(0,0,0,0.3);
    
    @media (max-width: 768px) {
        font-size: 3rem;
    }
}

.filmFestival {
    display: block;
    font-size: 2.5rem;
    letter-spacing: 8px;
    margin-top: -10px;
    color: rgb(255, 255, 255);
    animation: glow 2s ease-in-out infinite alternate;
    
    @media (max-width: 768px) {
        font-size: 1.8rem;
    }
}

.subtitle {
    font-size: 1.5rem;
    margin-bottom: 30px;
    min-height: 40px;
    color: rgba(255,255,255,0.9);
    opacity: 0;
    animation: fadeInOut 3s infinite;
    
    @media (max-width: 768px) {
        font-size: 1.2rem;
    }
}

.date {
    font-size: 1.2rem;
    letter-spacing: 2px;
    background: rgba(255,255,255,0.1);
    padding: 15px 30px;
    border-radius: 30px;
    display: inline-block;
    backdrop-filter: blur(5px);
    border: 1px solid rgba(255,255,255,0.2);
    animation: pulse 2s ease-in-out infinite;
    
    @media (max-width: 768px) {
        font-size: 1rem;
        padding: 10px 20px;
    }
}

@keyframes glow {
    from { text-shadow: 0 0 10px #3e50b3; }
    to { text-shadow: 0 0 20px #3c25bb, 0 0 30px #4829b6; }
}

@keyframes fadeInOut {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
}

@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
}

