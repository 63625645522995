.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.98);
  backdrop-filter: blur(15px);
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);

  &.hidden {
    transform: translateY(-100%);
  }

  &.scrolled {
    background: rgba(255, 255, 255, 0.99);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  }
}

.container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 2rem;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
}

.logo {
  font-family: 'Inter', sans-serif;
  font-size: 1.8rem;
  font-weight: 700;
  color: #4F46E5;
  text-decoration: none;
  transition: color 0.3s ease;
  display: flex;
  align-items: baseline;

  &:hover {
    color: #6366F1;
  }
}

.logoSub {
  font-size: 1rem;
  font-weight: 400;
  margin-left: 0.75rem;
  color: #6B7280;
}

.desktopNav {
  display: flex;
  align-items: center;
  gap: 2rem;

  @media (max-width: 1024px) {
    display: none;
  }
}

.navItemWrapper {
  position: relative;
}

.navItemWithSubmenu {
  position: relative;

  &:hover .submenu {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

.navItem {
  font-weight: 500;
  color: #374151;
  text-decoration: none;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
  border-radius: 8px;

  &:hover {
    color: #4F46E5;
    background: rgba(79, 70, 229, 0.05);
  }
}

.chevron {
  transition: transform 0.2s ease;
  color: #9CA3AF;
}

.submenu {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  background: white;
  border-radius: 12px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 0;
  min-width: 240px;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: all 0.2s ease;
}

.submenuItem {
  display: block;
  padding: 0.75rem 1.5rem;
  color: #4B5563;
  text-decoration: none;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(79, 70, 229, 0.05);
    color: #4F46E5;
  }
}

.mobileMenuButton {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  color: #4B5563;
  padding: 0.75rem;
  border-radius: 8px;
  transition: all 0.2s ease;

  @media (max-width: 1024px) {
    display: block;
  }

  &:hover {
    color: #4F46E5;
    background: rgba(79, 70, 229, 0.05);
  }
}

.mobileMenu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  background: rgba(255, 255, 255, 0.98);
  backdrop-filter: blur(20px);
  padding: 2rem 1.5rem;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  transform: translateX(100%);
  
  @media (min-width: 1024px) {
    display: none;
  }
}

.mobileMenuHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  margin-bottom: 1rem;
}

.mobileMenuCloseButton {
  background: none;
  border: none;
  padding: 0.5rem;
  color: #4F46E5;
  cursor: pointer;
  transition: all 0.2s ease;
  
  &:hover {
    opacity: 0.8;
  }
}

.mobileNavItems {
  flex-grow: 1;
  overflow-y: auto;
  padding-right: 0.5rem;
}

.mobileNavItemWrapper {
  margin: 0.5rem 0;
  border-radius: 12px;
  transition: all 0.2s ease;
  
  &:hover {
    background: rgba(79, 70, 229, 0.05);
  }
}

.mobileNavItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  color: #1F2937;
  font-weight: 500;
  text-decoration: none;
  font-size: 1.1rem;
}

.mobileSubmenu {
  padding-left: 1.5rem;
  margin: 0.5rem 0;
}

.mobileSubmenuItem {
  display: block;
  padding: 1rem;
  color: #4B5563;
  text-decoration: none;
  font-size: 1rem;
  border-radius: 8px;
  transition: all 0.2s ease;
  
  &:hover {
    color: #4F46E5;
    background: rgba(79, 70, 229, 0.05);
  }
}

.submenuIndicator {
  transition: transform 0.2s ease;
  color: #9CA3AF;
  
  &.rotated {
    transform: rotate(180deg);
  }
}