// Category.module.scss
.wrapper {
    background: linear-gradient(135deg, #ffffff 0%, #2A5EE6 100%);
    position: relative;
    overflow: hidden;
    padding: 120px 0;
}

.decorativeWaves {
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 150px;
    background: url('data:image/svg+xml;utf8,<svg ...></svg>');
    background-size: cover;
    opacity: 0.1;
}

.inner {
    position: relative;
    z-index: 1;
}

.categoryGroups {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 30px;
    margin: 60px 0;
}

.groupCard {
    padding: 30px;
    border-radius: 15px;
    text-align: center;
    color: white;
    box-shadow: 0 10px 30px rgba(0,0,0,0.2);
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        box-shadow: 0 15px 40px rgba(0,0,0,0.3);
    }
}

.groupIcon {
    font-size: 3rem;
    margin-bottom: 20px;
    filter: drop-shadow(0 2px 4px rgba(0,0,0,0.2));
}

.groupTitle {
    font-size: 1.5rem;
    margin: 0;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.buttonBlock {
    text-align: center;
    margin-top: 40px;
}

.button {
    height: 60px;
    padding: 0 40px;
    font-size: 1.2rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    background: linear-gradient(45deg, #2A5EE6, #2048ac);
    border: none;
    border-radius: 30px;
    box-shadow: 0 5px 15px rgba(120, 102, 219, 0.3);
    transition: all 0.3s ease;

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 8px 20px rgba(74, 124, 218, 0.5);
    }
}

.modal {
    .ant-modal-content {
        background: linear-gradient(135deg, #0A1F5C 0%, #2A5EE6 100%);
        color: white;
        border-radius: 20px;
        overflow: hidden;
    }

    .ant-modal-header {
        background: transparent;
        border-bottom: 1px solid rgba(255,255,255,0.1);
    }

    .ant-modal-title {
        color: white !important;
        font-size: 2rem;
        text-align: center;
    }
}

.categoryGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    max-height: 60vh;
    overflow-y: auto;
    padding: 20px;
}

.categoryCard {
    background: rgba(255,255,255,0.1);
    padding: 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;

    &:hover {
        background: rgba(255,255,255,0.2);
        transform: translateX(10px);
    }
}

.categoryNumber {
    font-size: 1.5rem;
    font-weight: 700;
    color: #2A5EE6;
    margin-right: 20px;
    min-width: 40px;
}

.categoryName {
    font-size: 1.1rem;
    line-height: 1.4;
}