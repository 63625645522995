// About.module.scss
.body {
    background: linear-gradient(135deg, #0A1F5C 0%, #2A5EE6 100%);
    position: relative;
    overflow: hidden;
    padding: 80px 0;
}

.waveDecoration {
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 150px;
    background: url('data:image/svg+xml;utf8,<svg viewBox="0 0 1440 320" xmlns="http://www.w3.org/2000/svg"><path fill="%23ffffff" fill-opacity="0.1" d="M0,160L48,138.7C96,117,192,75,288,80C384,85,480,139,576,144C672,149,768,107,864,106.7C960,107,1056,149,1152,165.3C1248,181,1344,171,1392,165.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>');
    background-size: cover;
}

.bodyWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 60px;
    align-items: center;
    padding: 80px 0 0 0;
    position: relative;
    z-index: 1;

    @media (max-width: 1024px) {
        grid-template-columns: 1fr;
        gap: 40px;
    }
}

.textContainer {
    background: rgba(255, 255, 255, 0.95);
    padding: 40px;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease;

    &:hover {
        transform: translateY(-5px);
    }

    @media (max-width: 768px) {
        padding: 25px;
    }
}

.imageContainer {
    perspective: 1000px;
}

.imageFrame {
    background: linear-gradient(45deg, #2A5EE6, #4A7DFF);
    padding: 15px;
    border-radius: 15px;
    transform-style: preserve-3d;
    position: relative;
    overflow: hidden;
    
    &::before {
        content: '';
        position: absolute;
        top: -50%;
        left: -50%;
        width: 200%;
        height: 200%;
        background: linear-gradient(45deg, transparent, rgba(255,255,255,0.2), transparent);
        transform: rotate(45deg);
        animation: shine 4s infinite;
    }
}

.logoImage {
    width: 100%;
    height: auto;
    border-radius: 10px;
    transform: translateZ(30px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

@keyframes shine {
    0% { transform: rotate(45deg) translateX(-150%); }
    100% { transform: rotate(45deg) translateX(150%); }
}

// Для компонента BlockInfoText добавьте эти стили:
.title {
    font-size: 2.5rem;
    font-weight: 700;
    color: inherit;
    position: relative;
    margin-bottom: 30px;
    text-transform: uppercase;
    letter-spacing: 2px;
    
    &::after {
        content: '';
        position: absolute;
        bottom: -15px;
        left: 0;
        width: 60px;
        height: 4px;
        background: #2A5EE6;
        border-radius: 2px;
    }
}

.description {
    font-size: 1.1rem;
    line-height: 1.8;
    color: #444;
    max-width: 600px;
}