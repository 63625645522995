// Rules.module.scss
.wrapper {
    background: #f8f9fa;
    padding: 80px 0;
}

.content {
    max-width: 1000px;
    margin: 0 auto;
}

.rulesList {
    margin-top: 40px;
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0,0,0,0.08);
}

.panel {
    border-bottom: 1px solid #eee !important;
    transition: all 0.3s ease;
    
    &:last-child {
        border-bottom: none !important;
    }
    
    &:hover {
        background: rgba(42, 94, 230, 0.03);
    }
}

.panelHeader {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 1rem;
    padding: 16px 24px;
    color: #2A5EE6;
    
    .icon {
        margin-right: 12px;
        font-size: 1.2rem;
        color: #2A5EE6;
    }
}

.panelContent {
    padding: 16px 24px;
    color: #444;
    line-height: 1.7;
    border-left: 3px solid #2A5EE6;
    margin-left: 32px;
    background: rgba(42, 94, 230, 0.02);
}

// Переопределение стилей Ant Design
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0;
}

.ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
}

.ant-collapse-item:last-child > .ant-collapse-content {
    border-radius: 0 0 12px 12px;
}