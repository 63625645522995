.wrapper{
    background: url("../../../../public/images/Aiming/back.png") no-repeat;
    font-family: 'Krona One', sans-serif;
    background-size: cover;
    height: 450px;
    display: flex;
    @media screen and (max-width: 768px) {
        height: 100%;
    }
}

.inner{
    justify-content: space-between;
    padding: 100px 0;
    width: 60%;
    @media screen and (max-width: 768px) {
        width: 100%;
    }
}

.title{
    text-transform: capitalize;
    color: rgb(255, 255, 255);
    font-size: 50px;
    font-weight: 700;
    @media screen and (max-width: 768px) {
        font-size: 30px;
    }
}


.button{
    margin: 20px 0px 0px 0px ;
    width: 300px;
    height: 100px;
    transition: all 0.5s ease-out;
    img{
        width: 100%;
        height: 100%;
        border-radius: 16px;
    }
    &:hover{
        width: 310px;
        height: 105px;
        margin: 20px 0px 0px 0px ;
    }
    @media screen and (max-width: 576px) {
        width: 60%;
        height: 75px;
    }
    @media screen and (max-width: 400px) {
        width: 80%;
        height: 75px;
    }
}
