// Features.module.scss
.wrapper {
    background: linear-gradient(135deg, #0A1F5C 0%, #2A5EE6 100%);
    position: relative;
    overflow: hidden;
    padding: 120px 0;
    
    &::before {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%;
      height: 150px;
      background: url('data:image/svg+xml;utf8,<svg viewBox="0 0 1440 320" xmlns="http://www.w3.org/2000/svg"><path fill="%23ffffff" fill-opacity="0.1" d="M0,160L48,138.7C96,117,192,75,288,80C384,85,480,139,576,144C672,149,768,107,864,106.7C960,107,1056,149,1152,165.3C1248,181,1344,171,1392,165.3L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>');
      background-size: cover;
      z-index: 0;
    }
  }
  
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 60px;
    align-items: center;
    position: relative;
    z-index: 1;
  
    @media (max-width: 1024px) {
      grid-template-columns: 1fr;
      gap: 40px;
    }
  }
  
  .imageContainer {
    perspective: 1000px;
    position: relative;
    
    &::before {
      content: '';
      position: absolute;
      top: -50%;
      left: -50%;
      width: 200%;
      height: 200%;
      background: linear-gradient(45deg, transparent, rgba(255,255,255,0.2), transparent);
      transform: rotate(45deg);
      animation: shine 4s infinite;
    }
  }
  
  .imageFrame {
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    transform-style: preserve-3d;
    box-shadow: 
      0 20px 40px rgba(0,0,0,0.3),
      0 0 50px rgba(42, 94, 230, 0.2);
    background: linear-gradient(45deg, #2A5EE6, #4A7DFF);
    padding: 15px;
  
    &::before {
      content: '';
      position: absolute;
      inset: 0;
      background: linear-gradient(45deg, 
        rgba(42, 94, 230, 0.4) 0%,
        rgba(255, 215, 0, 0.2) 100%);
      z-index: 1;
      mix-blend-mode: overlay;
    }
  }
  
  .featureImage {
    width: 100%;
    height: 600px;
    object-fit: cover;
    transform: translateZ(30px);
    transition: transform 0.4s ease;
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  
    .imageContainer:hover & {
      transform: translateZ(40px);
    }
  
    @media (max-width: 768px) {
      height: 400px;
    }
  }
  
  .imageOverlay {
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.4s ease;
    backdrop-filter: blur(2px);
  
    .imageContainer:hover & {
      opacity: 1;
    }
  }
  
  .overlayText {
    color: white;
    font-size: 2rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-shadow: 0 2px 10px rgba(0,0,0,0.3);
    transform: translateY(20px);
    transition: transform 0.4s ease;
  
    .imageContainer:hover & {
      transform: translateY(0);
    }
  }
  
  @keyframes shine {
    0% { transform: rotate(45deg) translateX(-150%); }
    100% { transform: rotate(45deg) translateX(150%); }
  }

  // Features.module.scss
.waveDecoration {
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 150px;
    background: url('data:image/svg+xml;utf8,<svg...></svg>');
    background-size: cover;
    z-index: 0;
    transform: scaleX(-1); // Добавлено отражение
}

.imageContainer::before {
    animation: shine 4s infinite;
    transform: rotate(-45deg); // Изменен угол
}

@keyframes shine {
    0% { transform: rotate(-45deg) translateX(150%); }
    100% { transform: rotate(-45deg) translateX(-150%); }
}

.imageOverlay {
    justify-content: flex-start; // Текст оверлея слева
    padding-left: 2rem;
}

// Для текстового блока
.textContainer {
    order: 1; // Меняем порядок элементов
}

.imageContainer {
    order: 2;
}

@media (max-width: 1024px) {
    .textContainer,
    .imageContainer {
        order: initial;
    }
}